/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg"; //not in use
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsmobile from "../../../aws-exports";
import { Amplify } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveAuthToken, saveUserAttrubutes } from "../../../store/auth";
import { fetchAuthSession, fetchUserAttributes } from "@aws-amplify/auth";


Amplify.configure(awsmobile)

function Basic() {
  const { userAttributes } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const fetchData = async () => {
    try {
      const userAtt = await fetchUserAttributes();
      const authToken =
        await fetchAuthSession()?.tokens?.accessToken;
      if (userAtt) {
        dispatch(saveAuthToken(authToken));
        dispatch(saveUserAttrubutes(userAtt));
      }

      if (userAttributes["custom:role"] === 'admin') {
        navigate('/dashboard');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error("Error fetching user attributes:", error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
      <Authenticator>
            {({ signOut, user }) => {
              if (user) {
                fetchData(user); // Fetch data when the user logs in
              }

              return (
                <MDBox>
                  <MDTypography variant="h5" fontWeight="medium" color="text">
                    Hello, {userAttributes?.name || userAttributes?.email}
                  </MDTypography>
                </MDBox>
              );
            }}
          </Authenticator>
          
      </Card>
    </BasicLayout>
  );
}

export default Basic;
