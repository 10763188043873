import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Uses localStorage for web
import authReducer from "./auth";
import sevresReducer from "./sevres";

// Define the persist configuration for only authReducer
const authPersistConfig = {
  key: "auth",
  storage, // Specifies localStorage as the storage option
};

// Wrap only the authReducer with persistReducer
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

// Combine reducers, with only authReducer being persisted
const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  sevres: sevresReducer, // Not persisted
});

// Create the store with the combined reducer
const store = configureStore({
  reducer: rootReducer,
  middleware: (defaultMiddleWare) =>
    defaultMiddleWare({
      thunk: true,
      serializableCheck: false, // Disable serializable check for redux-persist
    }),
});

// Create a persistor to manage persistence
export const persistor = persistStore(store);

export default store;
