import axios from 'axios';
import { toast } from 'react-toastify';
import {isExpectedError} from '../utils/helperFunctions'
import { signOut, getCurrentUser, fetchAuthSession } from "aws-amplify/auth";


axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(async (config) => {
  try {
    const session = await fetchAuthSession();
    const token = session?.tokens?.idToken;
    config.headers.Authorization = `Bearer ${token}`;
  } catch (error) {
    console.error("Error fetching auth token:", error);
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});



axios.interceptors.response.use(null, error => {
  if (!isExpectedError(error)) {
    toast.error('An unexpected error has occured!', { containerId: 'network-error' });
  }

  return Promise.reject(error)
})

export default axios;
