import { recognisedColumns, recognisedKeyword } from "./constants";

export const getQueryParams = (params) => {
  const queryParams = {};

  for (let key in params) {
    if (params[key] || params[key] === 0) {
      queryParams[key] = params[key];
    }
  }
  return queryParams;
};

export const hasUndefined = (params) => {
  for (let key in params) {
    if (params[key] === undefined) return true;
  }
  return false;
};

export const hasAccess = (access) => {
  for (let key in access) {
    if (access[key]) return true;
  }
  return false;
};

// Function to format the date as "DDMMYY_HHMM"
export const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}${month}${year}_${hours}${minutes}`;
};

export const isExpectedError = (error) => {
  return error.response && error.response.status >= 400 && error.response.status < 500;
};

export const filterColumns = (data) => {
  return data.map((row) =>
    Object.keys(row).reduce((acc, col) => {
      // Check for exact match in recognisedColumns
      if (recognisedColumns.includes(col)) {
        acc[col] = row[col];
      }
      // Check for partial match in recognisedKeyword
      else if (recognisedKeyword.some((keyword) => col.includes(keyword))) {
        acc[col] = row[col];
      }
      return acc;
    }, {})
  );
};

// Helper function to convert binary string to array buffer
export const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

// Helper function to handle date or date-time formatting
export const handleDateOrDateTime = (value) => {
  if (value instanceof Date) {
    return formatDateTime(value); // Format JavaScript Date object
  } else if (typeof value === "string" && isDateOrDateTime(value)) {
    // If the value is a string that resembles a date or date-time, return it as-is
    return value;
  }
  return value; // For non-date values, return the original
};

// Helper function to detect if a string is a date or date-time
export const isDateOrDateTime = (value) => {
  // Simple regex for date and date-time detection
  const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/; // e.g., 01/01/2024
  const dateTimeRegex = /^\d{1,2}\/\d{1,2}\/\d{2,4} \d{1,2}:\d{2}(:\d{2})?$/; // e.g., 7/29/24 15:57:28
  return dateRegex.test(value) || dateTimeRegex.test(value);
};

// Helper function to format date-time
export const formatDateTime = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const excelDateToJSDate = (excelDate) => {
  const excelStartDate = new Date(1899, 11, 30); // Excel's base date
  return new Date(excelStartDate.getTime() + excelDate * 86400000); // Add days in milliseconds
};

export const calculateAgeRange = (dobExcel) => {
  if (!dobExcel) return null; // Return null if dob is invalid

  const dob = excelDateToJSDate(dobExcel); // Convert Excel integer to JS Date
  const today = new Date();
  let age = today.getFullYear() - dob.getFullYear();
  const hasHadBirthdayThisYear =
    today.getMonth() > dob.getMonth() ||
    (today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate());

  if (!hasHadBirthdayThisYear) {
    age -= 1;
  }

  if (age < 16) {
    return 'under 16';
  } else if (age >= 16 && age <= 17) {
    return '16-17';
  } else if (age >= 18 && age <= 24) {
    return '18-24';
  } else if (age >= 25 && age <= 39) {
    return '25-39';
  } else {
    return '40+';
  }
};

export const encryptWithKeys = async (data, userKey, serverKey) => {
  if (!data || !userKey || !serverKey) {
    throw new Error("Missing required inputs for encryption");
  }

  const combinedKey = new TextEncoder().encode(userKey + serverKey);
  const key = await crypto.subtle.digest("SHA-256", combinedKey);
  const keyData = new Uint8Array(key);

  const hmacKey = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "HMAC", hash: { name: "SHA-256" } },
    false,
    ["sign"]
  );

  const signature = await crypto.subtle.sign(
    "HMAC",
    hmacKey,
    new TextEncoder().encode(data)
  );

  const hashArray = Array.from(new Uint8Array(signature));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};
