/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput"; // Import MDInput component
import Select from "@mui/material/Select"; // Import Select component
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem component
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import MDButton from "components/MDButton"; // Import MDButton component
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { submitMissingData } from "./../../../store/sevres";

export default function data(results, file) {
  const { isSubmittingMissingData } = useSelector((state) => state.sevres);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Sort results by "Appointment Date" in descending order
  const sortedResults = results.sort((a, b) => {
    const dateA = new Date(a["Appointment Date"].split("/").reverse().join("-"));
    const dateB = new Date(b["Appointment Date"].split("/").reverse().join("-"));
    return dateB - dateA;
  });

  // Default to empty array
  const [formData, setFormData] = useState(
    sortedResults.map((result) => {
      let selectedAction = "";
      const otherTextColumn = result["Which other text column?"];
      if (otherTextColumn.includes("Primary") || otherTextColumn.includes("Presenting")) {
        selectedAction = "low_freq_issue";
      } else if (otherTextColumn.includes("Contributing")) {
        selectedAction = "low_freq_contrib";
      } else if (otherTextColumn.includes("Diagnosed")) {
        selectedAction = "low_freq_condition";
      }
      return {
        ...result,
        selectedAction,
        reasonForSelection: "",
        otherTextValue: result["Other Text Value"] || "",
        // isEditable: !result["Other Text Value"], // Determine if the field should be editable
      };
    })
  );

  const handleInputChange = (index, field, value) => {
    const newFormData = [...formData];
    newFormData[index][field] = value;
    setFormData(newFormData);
  };

  const isFormComplete = formData.every(
    (data) => data.selectedAction && data.reasonForSelection && data.otherTextValue
  );

  const handleUpload = async () => {
    const strippedFormData = {
      fileId: file?.file_id,
      data: formData,
      // data: formData.map(({ isEditable, ...rest }) => rest), // Remove isEditable from rows
    };
    await dispatch(submitMissingData(strippedFormData));
    navigate(`/upload`);
  };

  return {
    columns: [
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Source ID
          </MDTypography>
        ),
        accessor: "sourceId",
        width: "10%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Student / Learner ID
          </MDTypography>
        ),
        accessor: "studentLearnerId",
        width: "15%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Case No
          </MDTypography>
        ),
        accessor: "linkedToCaseId",
        width: "15%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Appointment Date
          </MDTypography>
        ),
        accessor: "appointmentDate",
        width: "15%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Practitioner Name
          </MDTypography>
        ),
        accessor: "practitionerName",
        width: "15%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Which Reason Code
          </MDTypography>
        ),
        accessor: "otherTextColumn",
        width: "20%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            What else selected
          </MDTypography>
        ),
        accessor: "elseSelected",
        width: "20%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Free Text
          </MDTypography>
        ),
        accessor: "otherTextValue",
        width: "20%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Selected Category
          </MDTypography>
        ),
        accessor: "selectedAction",
        width: "10%",
        align: "center",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Reason for Selection
          </MDTypography>
        ),
        accessor: "reasonForSelection",
        width: "10%",
        align: "center",
      },
    ],

    rows: [
      ...formData?.map((result, index) => ({
        appointmentDate: (
          <MDTypography component="a" variant="p" color="text">
            {result["Appointment Date"]}
          </MDTypography>
        ),
        studentLearnerId: (
          <MDTypography component="a" variant="p" color="text">
            {result["Student / Learner ID"]}
          </MDTypography>
        ),
        sourceId: (
          <MDTypography component="a" variant="p" color="text">
            {result["Source ID"]}
          </MDTypography>
        ),
        linkedToCaseId: (
          <MDTypography component="a" variant="p" color="text">
            {result["Linked to Case ID"]}
          </MDTypography>
        ),
        practitionerName: (
          <MDTypography component="a" variant="p" color="text">
            {result["Practitioner Name"]}
          </MDTypography>
        ),
        elseSelected: (
          <MDTypography component="a" variant="p" color="text">
            {result["What else selected"]}
          </MDTypography>
        ),
        otherTextColumn: (
          <MDTypography component="a" variant="p" color="text">
            {result["Which other text column?"]}
          </MDTypography>
        ),
        otherTextValue: (
          <MDInput
            value={result.otherTextValue}
            onChange={(e) => handleInputChange(index, "otherTextValue", e.target.value)}
            fullWidth
          />
        ),
        // otherTextValue: result.isEditable ? (
        //   <MDInput
        //     value={result.otherTextValue}
        //     onChange={(e) => handleInputChange(index, "otherTextValue", e.target.value)}
        //     fullWidth
        //   />
        // ) : (
        //   <MDTypography component="a" variant="p" color="text">
        //     {result.otherTextValue}
        //   </MDTypography>
        // ),
        selectedAction: (
          <Select
            value={result.selectedAction || "0"}
            onChange={(e) => handleInputChange(index, "selectedAction", e.target.value)}
          >
            <MenuItem value="0" disabled>
              Select an action
            </MenuItem>
            <MenuItem value="low_freq_issue">
              Low Frequency Mental Health Presenting Issue Other - monitor use
            </MenuItem>
            <MenuItem value="low_freq_contrib">
              Low Frequency Contributing Factor - monitor use
            </MenuItem>
            <MenuItem value="low_freq_condition">
              Low Frequency Diagnosed MH or ND - monitor use
            </MenuItem>
            <MenuItem value="amend_code">
              Suggestion to amend / update existing reason code
            </MenuItem>
            <MenuItem value="inappropriate_notes">Inappropriate use - case notes</MenuItem>
            <MenuItem value="inappropriate_existing_selected">
              Inappropriate use - existing code(s) sufficient (already selected)
            </MenuItem>
            <MenuItem value="inappropriate_existing_not_selected">
              Inappropriate use - existing code(s) sufficient (not selected)
            </MenuItem>
            <MenuItem value="inappropriate_different_question">
              Inappropriate use - capture under a different question
            </MenuItem>
            <MenuItem value="tbd_discussion">TBD - discussion required</MenuItem>
          </Select>
        ),
        reasonForSelection: (
          <MDInput
            value={result.reasonForSelection}
            onChange={(e) => handleInputChange(index, "reasonForSelection", e.target.value)}
            fullWidth
          />
        ),
      })),
      {
        appointmentDate: "",
        studentLearnerId: "",
        sourceId: "",
        otherTextColumn: "",
        otherTextValue: "",
        selectedAction: "",
        reasonForSelection: (
          <MDBox display="flex" justifyContent="center" alignItems="center">
            {isSubmittingMissingData ? (
              <CircularProgress />
            ) : (
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleUpload}
                disabled={!isFormComplete}
              >
                Upload
              </MDButton>
            )}
          </MDBox>
        ),
      },
    ],
  };
}
