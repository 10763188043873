/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function submissionsDataTable(submissions, handleRowClick, handlePushClick) {
  const getStatusColor = (status) => {
    switch (status) {
      case "FILE_FINISHED_INDEXING":
        return "success";
      case "FILE_SUBMITTED_AWAITING_INDEXING":
        return "info";
      case "FILE_UPLOADED_FOR_PROCESSING":
        return "info";
      case "FILE_PROCESSED_FOR_EDIT":
        return "warning";
      case "FILE_PROCESSED_CLEAN":
        return "warning";
      case "FILE_ERROR_INDEXING":
        return "error";
      case "FILE_STARTED_INDEXING":
        return "info";
      case "FILE_REJECTED":
        return "error";
      default:
        return "dark";
    }
  };

  const getDisplayStatus = (status) => {
    switch (status) {
      case "FILE_FINISHED_INDEXING":
        return "Data is Ready";
      case "FILE_PROCESSED_FOR_EDIT":
        return "Available for Edit (Missing Values)";
      case "FILE_SUBMITTED_AWAITING_INDEXING":
        return "Data Submitted - Awaiting Indexing";
      case "FILE_UPLOADED_FOR_PROCESSING":
        return "File Uploaded - Processing...";
      case "FILE_ERROR_INDEXING":
        return "Data Submitted - Indexing Error";
      case "FILE_STARTED_INDEXING":
        return "Data Submitted - Indexing Started";
      case "FILE_REJECTED":
        return "File Validation - Rejected";
      case "FILE_PROCESSED_CLEAN":
        return "Click to push for index - No Edits Required";
      default:
        return "Initiated";
    }
  };

  const getClickableRow = (status) => {
    switch (status) {
      case "Completed":
        return "";
      case "FILE_PROCESSED_FOR_EDIT":
        return "a";
      case "Failed":
        return "";
      case "Pending":
        return "";
      case "FILE_PROCESSED_CLEAN":
        return "a";
      default:
        return "";
    }
  };

  return {
    columns: [
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Date Uploaded
          </MDTypography>
        ),
        accessor: "date_upload",
        width: "25%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            File Key
          </MDTypography>
        ),
        accessor: "file_key",
        width: "25%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            File Name
          </MDTypography>
        ),
        accessor: "file_name",
        width: "25%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            File Status
          </MDTypography>
        ),
        accessor: "file_status",
        width: "25%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            File Summary
          </MDTypography>
        ),
        accessor: "summary",
        width: "25%",
        align: "left",
      },
    ],

    rows: submissions?.map((submission) => ({
      date_upload: (
        <MDTypography
          component={getClickableRow(submission.file_status)}
          variant="p"
          href="#"
          color={getStatusColor(submission.file_status)}
          onClick={
            submission.file_status === "FILE_PROCESSED_FOR_EDIT"
              ? () => handleRowClick(submission.file_id)
              : submission.file_status === "FILE_PROCESSED_CLEAN"
              ? () => handlePushClick(submission.file_id)
              : undefined
          }
        >
          {submission.timestamp}
        </MDTypography>
      ),
      file_key: (
        <MDTypography
          component={getClickableRow(submission.file_status)}
          variant="p"
          href="#"
          color={getStatusColor(submission.file_status)}
          onClick={
            submission.file_status === "FILE_PROCESSED_FOR_EDIT"
              ? () => handleRowClick(submission.file_id)
              : submission.file_status === "FILE_PROCESSED_CLEAN"
              ? () => handlePushClick(submission.file_id)
              : undefined
          }
        >
          {submission.file_id}
        </MDTypography>
      ),
      file_name: (
        <MDTypography
          component={getClickableRow(submission.file_status)}
          variant="p"
          href="#"
          color={getStatusColor(submission.file_status)}
          onClick={
            submission.file_status === "FILE_PROCESSED_FOR_EDIT"
              ? () => handleRowClick(submission.file_id)
              : submission.file_status === "FILE_PROCESSED_CLEAN"
              ? () => handlePushClick(submission.file_id)
              : undefined
          }
        >
          {submission.upload_file_name}
        </MDTypography>
      ),
      file_status: (
        <MDTypography
          component={getClickableRow(submission.file_status)}
          variant="p"
          href="#"
          color={getStatusColor(submission.file_status)}
          onClick={
            submission.file_status === "FILE_PROCESSED_FOR_EDIT"
              ? () => handleRowClick(submission.file_id)
              : submission.file_status === "FILE_PROCESSED_CLEAN"
              ? () => handlePushClick(submission.file_id)
              : undefined
          }
        >
          {getDisplayStatus(submission.file_status)}
        </MDTypography>
      ),
      summary: (
        <MDTypography
          component={getClickableRow(submission.file_status)}
          variant="p"
          href="#"
          color={getStatusColor(submission.file_status)}
          onClick={
            submission.file_status === "FILE_PROCESSED_FOR_EDIT"
              ? () => handleRowClick(submission.file_id)
              : submission.file_status === "FILE_PROCESSED_CLEAN"
              ? () => handlePushClick(submission.file_id)
              : undefined
          }
        >
          {submission.summary}
        </MDTypography>
      ),
      // Add hover effect
      style: { cursor: "pointer", backgroundColor: "white" },
      onMouseEnter: (e) => {
        e.currentTarget.style.backgroundColor = "#e0e0e0";
        e.currentTarget.style.fontWeight = "bold";
        e.currentTarget.style.textDecoration = "underline";
      },
      onMouseLeave: (e) => {
        e.currentTarget.style.backgroundColor = "white";
        e.currentTarget.style.fontWeight = "normal";
        e.currentTarget.style.textDecoration = "none";
      },
    })),
  };
}
