import React from "react";
import { useDropzone } from "react-dropzone";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import "./index.css";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

function Dropzone({ onDrop, accept, open, disabled, files }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.ms-excel": [".xls"],
      "text/csv": [".csv"],
    },
    maxFiles: 1,
    disabled,
  });

  const fileList = files.map((file) => (
    <li key={file.path} className="file-list-item">
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div
    // eslint-disable-next-line
      {...getRootProps({ className: `dropzone ${disabled ? "dropzone-disabled" : ""}` })}
      style={{ pointerEvents: disabled ? "none" : "auto" }}
    >
      {/* eslint-disable-next-line */}
      <input className="input-zone" {...getInputProps()} disabled={disabled} />
      <Card>
        {isDragActive ? (
          <MDTypography variant="h6" fontWeight="medium">
            Release to drop the file here
          </MDTypography>
        ) : (
          <MDTypography variant="h6" fontWeight="medium">
            Click to select file or drag’n’drop your Excel or CSV file here.
          </MDTypography>
        )}
      </Card>
      <aside>
        <ul>{fileList}</ul>
      </aside>
    </div>
  );
}

export default Dropzone;
