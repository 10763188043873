import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signOut, getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { useNavigate } from 'react-router-dom';

const initialAuthState = {
  authToken: null,
  userAttributes: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    saveAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveUserAttrubutes.fulfilled, (state, action) => {
        state.userAttributes = action?.payload;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.userAttributes = null;
        state.authToken = null;
        state.isAuthenticated = false;
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        const { authToken, userAttributes } = action?.payload;
        state.authToken = authToken;
        state.userAttributes = userAttributes;
        state.isAuthenticated = !!authToken;
      });
  },
});

export const { setAuthToken, setUserAttributes, clearUser} = authSlice.actions;

export const saveAuthToken = createAsyncThunk("auth/token", async (value, thunkAPI) => {
  return value;
});

export const logoutUser = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  await signOut();
  return {
    authToken: null,
    userAttributes: null
  };
});

export const saveUserAttrubutes = createAsyncThunk("auth/attributes", async (value, thunkAPI) => {
  return value;
});

export const checkAuthStatus = createAsyncThunk("auth/checkAuthStatus", async (_, thunkAPI) => {
  try {
    const { authToken: existingToken, userAttributes: existingAttributes } = thunkAPI.getState().auth;
    if (existingAttributes && existingToken) {
      return {
        authToken: existingToken,
        userAttributes: existingAttributes,
      };
    } else {
      window.location.href = '/authentication/sign-in';
    }
  } catch (error) {
    window.location.href = '/authentication/sign-in';
  }
});

export default authSlice.reducer;
