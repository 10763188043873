import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPresignedURL as fetchPresignedURLApiCall,
  uploadOnPresignedURL as uploadOnPresignedURLApiCall,
  getValidationResults as getValidationResultsApiCall,
  getSubmissions as getSubmissionsApiCall,
  submitMissingData as submitMissingDataApiCall, // Import the API call
} from "./api";

const initialAuthState = {
  userAttributes: null,
  presignedUploadUrl: null,
  fileValidationResults: [],
  isFetchingValidationResults: false,
  submissions: [],
  isUploading: false,
  isFetchingSubmissions: false,
  isSubmittingMissingData: false, // Add new state
};

const sevresSlice = createSlice({
  name: "sevres",
  initialState: initialAuthState,
  reducers: {
    saveUserAttributes: (state, action) => {
      state.userAttributes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSignedUrl.pending, (state, action) => {
        state.isUploading = true;
      })
      .addCase(fetchSignedUrl.fulfilled, (state, action) => {
        state.presignedUploadUrl = action?.payload;
        state.isUploading = false;
      })
      .addCase(uploadOnPreSignedUrl.pending, (state, action) => {
        state.isUploading = false;
      })
      .addCase(uploadOnPreSignedUrl.fulfilled, (state, action) => {
        state.isUploading = false;
      })
      .addCase(getValidationResults.pending, (state, action) => {
        state.isFetchingValidationResults = true;
      })
      .addCase(getValidationResults.fulfilled, (state, action) => {
        state.fileValidationResults = action?.payload;
        state.isFetchingValidationResults = false;
      })
      .addCase(getSubmissions.pending, (state, action) => {
        state.isFetchingSubmissions = true;
      })
      .addCase(getSubmissions.fulfilled, (state, action) => {
        state.submissions = action?.payload;
        state.isFetchingSubmissions = false;
      })
      // Add cases for submitMissingData
      .addCase(submitMissingData.pending, (state, action) => {
        state.isFetchingSubmissions = true;
        state.isSubmittingMissingData = true;
      })
      .addCase(submitMissingData.fulfilled, (state, action) => {
        state.isFetchingSubmissions = false;
        state.isSubmittingMissingData = false;
      })
      .addCase(submitMissingData.rejected, (state, action) => {
        state.isSubmittingMissingData = false;
      });
  },
});

export const { saveUserAttributes } = sevresSlice.actions;

export const fetchSignedUrl = createAsyncThunk("sevres/getPresignedUrl", async (formData, thunkAPI) => {
  const response = await fetchPresignedURLApiCall(formData);
  return JSON.parse(response.data.body);
});

export const uploadOnPreSignedUrl = createAsyncThunk("sevres/uploadOnPreSignedUrl", async (file, thunkAPI) => {
  const state = thunkAPI.getState().sevres;
  const url = state?.presignedUploadUrl?.presigned_url;
  const response = await uploadOnPresignedURLApiCall(file, url);
  return JSON.parse(response.data.body);
}); 

export const getValidationResults = createAsyncThunk("sevres/getValidationResults", async (formData, thunkAPI) => {
  const response = await getValidationResultsApiCall(formData);
  return response.data.body;
});

export const getSubmissions = createAsyncThunk("sevres/getSubmissions", async (thunkAPI) => {
  const response = await getSubmissionsApiCall();
  return JSON.parse(response.data.body);
});

// Add the new thunk for submitting missing data
export const submitMissingData = createAsyncThunk("sevres/submitMissingData", async (formData, thunkAPI) => {
  const response = await submitMissingDataApiCall(formData);
  return response.data;
});

export default sevresSlice.reducer;
